import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchAdminRows } from "../Dashboard/Admin/AdminQueries/useAdminRows.jsx";
import { fetchClientRows } from "../Dashboard/Client/ClientQueries/useClientRows.jsx";
import { RenderSnackbar } from "../SnackbarAlerts/RenderSnackbar";
import useManualLogin from "./LoginQueries/manualLogin";
import { decodeCompanyCode } from "./companyCodeParser.js";
import "./styles/login.css";

// Login component
const Login = () => {
  // state variables & hook declarations
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const enteredPassword = useRef(null);
  const clientId = useSelector((state) => state.client.id);
  const user = useSelector((state) => state.user);
  const isAdmin = useSelector((state) => state.user.admin);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [adminUser, setAdminUser] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [verified, setVerified] = useState(false);
  // Gain access to the global query cache/instance
  const queryClient = useQueryClient();
  // Custom mutation hook for logging user in with the company code
  const manualLogin = useManualLogin(
    dispatch,
    setAdminUser,
    setCompanyName,
    setOpenErrorSnackbar,
    setOpenSuccessSnackbar,
    setVerified,
    user,
    queryClient,
  );

  // Automatically add decoded company code to password field if it exists in the user object
  useEffect(() => {
    if (password === "" && user.companyCode && user.companyCode !== "") {
      setPassword(() => decodeCompanyCode(user.companyCode));
    }
  }, []);

  // Sign in event handler that invokes manualLogins mutate function
  const handleSignIn = async (e) => {
    e.preventDefault();

    // disable button to prevent multiple clicks
    e.target.disabled = true;
    const code = password;

    // call the manualLogin mutation function
    const signInData = await manualLogin.mutateAsync(code);

    // Check the return value of the manualLogin mutation function
    if (typeof signInData === "object") {
      // If the user is an admin, fetch the admin rows
      if (signInData.is_admin) {
        await queryClient.ensureQueryData({
          queryKey: ["adminRows"],
          queryFn: () => fetchAdminRows(signInData.is_admin),
          revalidateIfStale: true,
        });
        // If the user is a client, fetch the client rows
      } else {
        const clientsId = signInData.client.id;
        await queryClient.ensureQueryData({
          queryKey: ["clientRows", clientsId],
          queryFn: () => fetchClientRows(clientsId),
          revalidateIfStale: true,
        });
      }
    }
    // re-enable the button
    e.target.disabled = false;
  };

  return (
    <>
      <div id="sign-in-container">
        <h1 id="sign-in-header">Company Portal Login</h1>
        <hr />
        <div id="sign-in-content-container">
          <div id="sign-in-p-container">
            <p id="sign-in-p">
              {
                "Company codes are automatically created upon creation of your company in the database. This code will be given out to the individual responsible for completing this request via email sent with the initial proposal. Upon successfully logging in, you will be redirected to the main dashboard where you will be prompted to add your information before continuing if you had not done so prior."
              }
            </p>
          </div>
          <div className="input-password-container">
            <label id="company-code-label">Company Code:</label>
            <FormControl
              variant="outlined"
              id="sign-in-form"
              classes={{ root: "form-sign-in-root" }}
            >
              <InputLabel
                id="password-label"
                htmlFor="password-input"
                classes={{ shrink: "password-label-shrink" }}
              >
                Enter Company Code:
              </InputLabel>
              <OutlinedInput
                id="password-outlined-input"
                type={showPassword ? "text" : "password"}
                inputRef={enteredPassword}
                value={password}
                onChange={(e) => {
                  setPassword(() => e.target.value);
                  enteredPassword.current.value = e.target.value;
                }}
                disabled={manualLogin.isLoading}
                classes={{
                  root: "password-root",
                  notchedOutline: "password-notchedOutline",
                  input: "password-root-input",
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                      id="password-icon-button"
                    >
                      {showPassword ? (
                        <VisibilityOffIcon
                          className="password-visibility"
                          id="hide-password"
                        />
                      ) : (
                        <VisibilityIcon
                          className="password-visibility"
                          id="show-password"
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
          <Button
            id="sign-in-button"
            type="submit"
            variant="contained"
            onClick={handleSignIn}
            disabled={password.length < 5 || manualLogin.isLoading || verified}
          >
            Sign In
          </Button>
        </div>
      </div>
      {/* Snackbar alerts for successful/unsuccessful log in attempts */}
      <RenderSnackbar
        open={openErrorSnackbar}
        message="There was an error logging in. Please try again."
        setOpen={setOpenErrorSnackbar}
        severity={"error"}
        type={"signInError"}
        stateValues={{ setPassword, enteredPassword, manualLogin }}
      />
      <RenderSnackbar
        open={openSuccessSnackbar}
        message={`Success! Welcome ${companyName}!`}
        setOpen={setOpenSuccessSnackbar}
        severity={"success"}
        type={"signInSuccess"}
        stateValues={{
          verified,
          user,
          clientId,
          isAdmin,
          queryClient,
          navigate,
          fetchAdminRows,
          fetchClientRows,
          adminUser,
        }}
      />
    </>
  );
};

export default Login;
